<template>
    <v-window class="mx-5 my-10" v-model="window">
        <v-window-item>
            <v-card class="mx-auto" max-width="600"  >
                <v-progress-linear
                    indeterminate
                    class="mx-auto text-center"
                    color="blue darken-4"
                    v-show="load"
                />
                <v-toolbar dark color="indigo darken-4">
                    <v-toolbar-title>
                        Busca el estado de un documento
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-text-field v-model="busqueda" filled rounded dense prepend-icon="mdi-email" :label="label_input_busqueda" >
                    </v-text-field>

                    <v-switch
                        v-model="tiene_token"
                        label="El usuario conserva el token del caso?"
                        color="indigo darken-3"
                        @change="busqueda=''"
                        hide-details
                    ></v-switch>
                    
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="buscar_estado_documento" dark block color="indigo darken-4"> Buscar <v-icon>mdi-magnify</v-icon> </v-btn>
                </v-card-actions>
            </v-card>
        </v-window-item>
        <v-window-item>
            <v-row>
                <v-toolbar dark color="indigo darken-4">
                    <v-btn icon @click="window--">
                        <v-icon>
                            mdi-arrow-left
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title>Coincidencias encontradas</v-toolbar-title>
                </v-toolbar>
            </v-row>
            <v-row v-if="notFound==false">
                <v-col>
                        <v-row  v-for="(caso,index2) in casos" :key="index2"  >
                            <v-stepper alt-labels class="mt-12 mx-auto" >
                                <h3 style="text-align:center"> <strong> Ciclo: </strong>   {{caso[0].token}}  </h3>
                                <h3 style="text-align:center"> <strong> Nombre: </strong>   {{caso[0].nombre_documento}}  </h3>
                                <v-stepper-header>
                                <v-row>
                                    <v-col v-for="(sector,index) in caso" :key="index">
                                        <v-stepper-step  :complete="sector.estado!='Pendiente'&& sector.estado!='Aceptado'" :step="index+1">
                                            <h4 style="text-align:center">  {{sector.sector}}</h4>
                                            <small> {{sector.estado}} </small>
                                            <small v-show="sector.estado=='Respondido'"> {{ fecha(sector.fecha_respondido) }}  </small>
                                            <small v-show="sector.estado=='Aceptado' || sector.estado=='Reasignado'"> {{ fecha(sector.fecha_aceptado) }} </small>
                                            <small v-show="sector.estado=='Rechazado'"> {{  fecha(sector.fecha_rechazado) }} </small>
                                        </v-stepper-step>
                                        
                                    </v-col>
                                </v-row>
                                
                                </v-stepper-header>
                            </v-stepper>
                        </v-row>
                
                </v-col>
                
                
            </v-row>
            <v-row v-else>
                <NotFound :mensaje="'No hay ningun caso asociado a estos datos'" />    
            </v-row>
        </v-window-item>
    </v-window>
</template>
<script>
export default {
    props: {
        token: {
            type: String,
            default: null,
        }
    },
    data(){
        return {
            window: 0,
            busqueda: "",
            tiene_token: false,
            sectores: [],
            notFound: false,
            casos: [],
            load: false,
            isActive: false,
        }
    },
    created(){
        if( this.token!=null ){
            this.busqueda = this.token,
            this.tiene_token = true
            this.buscar_estado_documento()
        }
    },
    computed: {
        label_input_busqueda(){
            let label = ""
            if(this.tiene_token){
                label = 'Ingrese el token del ciclo'
            }else{
               label = 'Ingrese el correo de contacto del remitente'
            }
            return label
        }
    },
    methods: {
      fecha(fecha){
         return new Date(fecha).toISOString().substr(0, 10)
      },
      async  buscar_estado_documento(){
          this.load = true
          this.notFound = false
          this.sectores = []
          this.casos = []
          let buscar 
            
            if(this.tiene_token){
                buscar =  JSON.stringify({"token": this.busqueda })
            }else{
                buscar =  JSON.stringify({"correo_de_contacto":this.busqueda})
            }
            buscar = encodeURIComponent(buscar)
            try {
                let response = await this.$store.dispatch('llamado_get',
                {url: 'https://siett.educandote.co/parse/classes/prueba01casos?where='+buscar,tipo_header:'parse'})
                console.log(response)
                let casos = [[]]
                if(this.tiene_token){
                    if(response.data.results.length>0){
                        casos[0].push(...response.data.results)
                         
                    }else{
                        this.notFound = true
                    }
                }else{
                    if(response.data.results.length>0){
                        let i = 0
                        let token = response.data.results[0].token
                        for (let index = 0; index < response.data.results.length; index++) {
                            if(token!=response.data.results[index].token){
                                token = response.data.results[index].token
                                casos.push([])
                                i++
                            }
                            casos[i].push(response.data.results[index])
                        }
                    }else{
                        this.notFound = true
                    }
                    
                }
                console.log(casos)
                this.casos = [...casos]
                this.window++
                
            } catch (error) {
                console.log(error)
                this.notFound = true
                this.$store.commit('error','Algo ha salido mal consultando el estado de este documento '+error.message)
            }
            this.load = false
        },
    },
}
</script>